/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { logo, baricon, baricon1, searchnormal, user06, } from "./imagepath";
import { Button, notification, Input } from "antd";
import { SearchOutlined } from '@ant-design/icons';
import { useSelectedUser } from "./staff/SelectedUserProvider";
import { useSearchPatient } from "./service/patient.service";
import { AudioOutlined } from '@ant-design/icons';

const { Search } = Input;
const suffix = (
    <AudioOutlined
        style={{
            fontSize: 16,
            color: '#1677ff',
        }}
    />
);
// const onSearch = (value, _e, info) => console.log(info?.source, value);

const Header = () => {
    const [position, setPosition] = useState('end');
    const { loginUser, loginRole, handleLogoutUser, loginBranch } = useSelectedUser();
    const { data: searchResult, mutate: searchPatient, isSuccess, error } = useSearchPatient();

    const navigate = useNavigate();
    const [searchTerm, setSearchTerm] = useState("");

    useEffect(() => {

        const handleClick = () => {
            if (!document.fullscreenElement) {
                document.documentElement.requestFullscreen();
            } else {
                if (document.exitFullscreen) {
                    document.exitFullscreen();
                }
            }
        };

        const maximizeBtn = document.querySelector(".win-maximize");

        if (isSuccess) {
            sessionStorage.setItem("searchResult", JSON.stringify(searchResult.data));
            if (searchResult.length === 0) {
                notification.info({
                    message: "Info",
                    description: "No search result found",
                });
                return;
            } else {
                navigate("/search-result", { state: { searchResult: searchResult.data } });
            }
        }
    }, [isSuccess]);

    const handlesidebar = () => {
        document.body.classList.toggle("mini-sidebar");
    };

    const handlesidebarmobilemenu = () => {
        document.body.classList.toggle("slide-nav");
        document.getElementsByTagName("html")[0].classList.toggle('menu-opened');
        document.getElementsByClassName("sidebar-overlay")[0].classList.toggle("opened");
    }

    const handleSearchChange = (event) => {
        const searchTerms = event.target.value;
        setSearchTerm(searchTerms);
    };

    const handleSearchSubmit = (event) => {
        // event.preventDefault()

        if (!searchTerm.trim()) {
            notification.error({
                message: "Error",
                description: "Search term cannot be empty",
            });
            return;
        }

        searchPatient({ searchText: searchTerm });
    };


    return (
        <div className="main-wrapper">
            <div className="header">
                <div className="header-left">
                    <Link to="/admin-dashboard" className="logo">
                        <img src={logo} height={35} alt="" />{" "}
                    </Link>
                </div>
                <Link id="toggle_btn" to="#" onClick={handlesidebar}>
                    <img src={baricon} alt="" />
                </Link>
                <Link id="mobile_btn" className="mobile_btn float-start" to="#" onClick={handlesidebarmobilemenu}>
                    <img src={baricon1} alt="" />
                </Link>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <form onSubmit={handleSearchSubmit}>
                        <div className="top-nav-search mob-view" style={{ marginTop: '1rem' }}>
                            <Search
                                placeholder="Patient Roaming"
                                allowClear
                                enterButton="Search"
                                size="large"
                                value={searchTerm}
                                onChange={handleSearchChange}
                                onSearch={handleSearchSubmit}
                            />
                        </div>
                    </form>
                    <ul className="nav user-menu" style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end', marginLeft: 'auto' }}>
                        <li className="nav-item dropdown has-arrow user-profile-list">
                            <Link to="#" className="dropdown-toggle nav-link user-link"
                                data-bs-toggle="dropdown" >
                                <div className="user-names">
                                    <h5>{loginUser?.firstName} {loginUser?.lastName}</h5>
                                    <span>{loginBranch?.name} - {loginRole?.roleName}</span>
                                </div>
                                <span className="user-img">
                                    <img src={user06} alt="Admin" />
                                </span>
                            </Link>
                            <div className="dropdown-menu">
                                <Link className="dropdown-item" to="/profile">
                                    My Profile
                                </Link>
                                <Link className="dropdown-item" to="/edit-profile">
                                    Edit Profile
                                </Link>
                                <Link className="dropdown-item" to="/settings">
                                    Settings
                                </Link>
                                <Link className="dropdown-item" to="#" onClick={handleLogoutUser}> Logout</Link>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default Header;
