/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import { useState } from "react";
import { Link } from "react-router-dom";
import { loginlogo } from "../../imagepath";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Eye, EyeOff } from "feather-icons-react/build/IconComponents";
import { useSelectedUser } from "../../staff/SelectedUserProvider";


const Login = () => {

  const { handleLoginUser } = useSelectedUser();
  
  const [loginPayload, setLoginPayload] = useState({'email': '', 'password': ''});

  const [passwordVisible, setPasswordVisible] = useState(true);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setLoginPayload({ ...loginPayload, [name]: value });
  };

  const handleLogin = (e) => {
    e.preventDefault();

    handleLoginUser(loginPayload);
  };

  return (
    <>
      <div className="main-wrapper login-body">
        <div className="container-fluid px-0">
          <div className="row">
            <div style={{width: '30rem', margin: '0 auto', top: '50%', left: '50%', position: 'absolute', transform: 'translate(-50%, -50%)'}}>
              <div className="login-wrapper">
                <div className="loginbox">
                  <div className="login-right">
                    <div className="login-right-wrap">
                      <div className="account-logo">
                        <Link to="/admin-dashboard">
                          <img src={loginlogo} alt="#" width={220} height={80}/>
                        </Link>
                      </div>
                      <h2>Login</h2>
                      {/* Form */}
                      <form onSubmit={handleLogin}>
                        <div className="form-group">
                          <label>
                            Email <span className="login-danger">*</span>
                          </label>
                          <input className="form-control" type="text" name="email" placeholder="Email or Username"
                            value={loginPayload.email}  onChange={handleChange} />
                        </div>
                        <div className="form-group">
                          <label>
                            Password <span className="login-danger">*</span>
                          </label>
                          <input type={passwordVisible ? 'password' : ''} placeholder="Password" className="form-control pass-input"
                            name="password" value={loginPayload.password} onChange={handleChange} />
                          <span
                            className="toggle-password"
                            onClick={togglePasswordVisibility}
                          >
                            {passwordVisible ? <EyeOff className="react-feather-custom" /> : <Eye className="react-feather-custom" />}
                          </span>
                        </div>
                        <div className="forgotpass">
                          <div className="remember-me">
                            <label className="custom_check mr-2 mb-0 d-inline-flex remember-me">
                              {" "}
                              Remember me
                              <input type="checkbox" name="radio" />
                              <span className="checkmark" />
                            </label>
                          </div>
                          <Link to="/forgotpassword">Forgot Password?</Link>
                        </div>
                        <div className="form-group login-btn">
                          <button type="submit" className="btn btn-primary btn-block">
                            Login
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="col-4"></div> */}
            {/* /Login Content */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;

