import './App.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import Approuter from './approuter';
import Login from './components/pages/login';
import ChangePassword from './components/pages/login/ChangePassword';
import LockScreen from './components/pages/login/LockScreen';
import { useSelectedUser } from './components/staff/SelectedUserProvider';
import '@fortawesome/fontawesome-free/css/all.min.css';

const App = ()  => {

    const { loginUser, loginBranch } = useSelectedUser();
    
    return loginUser !== null ? 
            (loginBranch !== null) ? 
                <Approuter /> : (loginUser.userStatus === 'NEW') ? <ChangePassword /> 
                    : <LockScreen /> 
                    : <Login />;
}

export default App;
