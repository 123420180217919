import { notification } from 'antd';
import instance from './index';
import { useMutation, useQuery, useQueryClient } from 'react-query';

// Onboarding APIs
const getAllHospitals = async () => await instance.get(`/hospital`);

const getMainMenuByHospital = async (hospitalId) => await instance.get(`/mainMenu/hospital/${hospitalId}`);

const getCompleteMenu = async (hospitalId) => await instance.get(`/mainMenu/complete/${hospitalId}`);

const addMainMenu = async (payload) => await instance.post('/mainMenu', payload);

const editMainMenu = async ({payload, mainMenuId}) => await instance.put(`/mainMenu/${mainMenuId}`, payload);

const getBConfig = async () => await instance.get('/bcConfig');

const addBConfig = async (payload) => await instance.post('/bcConfig', payload);

const editBConfig = async ({payload, bcConfigId}) => await instance.put(`/bcConfig/${bcConfigId}`, payload);

const getBcEndpoint = async (bcConfigId) => await instance.get(`/bcEndpoint/bcConfig/${bcConfigId}`);

const addBcEndpoint = async (payload) => await instance.post('/bcEndpoint', payload);

const editBcEndpoint = async ({payload, bcEndpointId}) => await instance.put(`/bcEndpoint/${bcEndpointId}`, payload);

//Onboarding Queries
export function useGetHospitals() {
    return useQuery(['getAllHospitals'], getAllHospitals);
}

export function useGetMainMenuByHospital(hospitalId) {
    return useQuery(['getMainMenuByHospital'], () => getMainMenuByHospital(hospitalId));
}

export function useGetCompleteMenu(hospitalId) {
    return useQuery(['getCompleteMenu', hospitalId], () => getCompleteMenu(hospitalId), {enabled: !!hospitalId});
}

export function useGetBConfig() {
    return useQuery(['getBConfig'], getBConfig);
}

export function useGetBcEndpoint(bcConfigId) {
    return useQuery(['getBcEndpoint'], () => getBcEndpoint(bcConfigId));
}

//Onboarding Mutations
export function useAddBConfig() {
    const queryClient = useQueryClient();

    return useMutation(addBConfig, {
        onSuccess: () => {
            notification.info({message: `BC Config created successfully`});
            queryClient.invalidateQueries(`getBConfig`);
        },
        onError: () => notification.info({message: `Error: ${queryClient.error.message}`})
    });
}

export function useEditBConfig() {
    const queryClient = useQueryClient();

    return useMutation(editBConfig, {
        onSuccess: () => {
            notification.info({message: `BC Config update successfully`});
            queryClient.invalidateQueries(`getBConfig`);
        },
        onError: () => notification.info({message: `Error: ${queryClient.error.message}`})
    });
}

export function useAddBcEndpoint() {
    const queryClient = useQueryClient();

    return useMutation(addBcEndpoint, {
        onSuccess: () => {
            notification.info({message: `BC Endpoint created successfully`});
            queryClient.invalidateQueries(`getBcEndpoint`);
        },
        onError: () => notification.info({message: `Error: ${queryClient.error.message}`})
    });
}

export function useEditBcEndpoint() {
    const queryClient = useQueryClient();

    return useMutation(editBcEndpoint, {
        onSuccess: () => {
            notification.info({message: `BC Endpoint update successfully`});
            queryClient.invalidateQueries(`getBcEndpoint`);
        },
        onError: () => notification.info({message: `Error: ${queryClient.error.message}`})
    });
}

export function useAddMainMenu() {
    const queryClient = useQueryClient();

    return useMutation(addMainMenu, {
        onSuccess: () => {
            notification.info({message: `Main Menu created successfully`});
            queryClient.invalidateQueries(`getMainMenuByHospital`);
        },
        onError: () => notification.info({message: `Error: ${queryClient.error.message}`})
    });
}

export function useEditMainMenu() {
    const queryClient = useQueryClient();

    return useMutation(editMainMenu, {
        onSuccess: () => {
            notification.info({message: `Main Menu update successfully`});
            queryClient.invalidateQueries(`getMainMenuByHospital`);
        },
        onError: () => notification.info({message: `Error: ${queryClient.error.message}`})
    });
}

