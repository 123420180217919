/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react'
import { login02, loginlogo } from '../../imagepath';
import { Link } from "react-router-dom";
import { useChangePassword } from '../../service/auth.service';
import { Input, notification } from 'antd';
import { Eye, EyeOff } from 'react-feather';
import { useSelectedUser } from '../../staff/SelectedUserProvider';


const ChangePassword = () => {

    const { loginUser, handleLogoutUser } = useSelectedUser();

    const [payload, setPayload] = useState({
        'username': loginUser?.username,
        'oldPassword': '',
        'newPassword': '',
        'confirmPassword': ''
    });

    const [passwordVisible, setPasswordVisible] = useState({
        'current': true,
        'new': true,
        'confirm': true
    });

    const changePassword = useChangePassword();

    useEffect(() => {

        if (changePassword.isSuccess) {
            notification.success({ message: 'Password changed successfully' });

            handleLogoutUser();
        }
    }, [changePassword.isSuccess]);

    const togglePass = (type) => {
        setPasswordVisible({ ...passwordVisible, [type]: !passwordVisible[type] });
    }

    const handleChange = (e) => {
        const { name, value } = e.target;

        setPayload({ ...payload, [name]: value });
    }

    const changePasswordRequest = (e) => {
        e.preventDefault();

        if (payload.newPassword !== payload.confirmPassword) {
            return notification.error({ message: 'Password does not match' });
        }

        changePassword.mutate(payload);


    }

    return (
        <div className="main-wrapper login-body">
            <div className="container-fluid px-0">
                <div className="row">
                    <div style={{ width: '30rem', margin: '0 auto', top: '50%', left: '50%', position: 'absolute', transform: 'translate(-50%, -50%)' }}>
                        <div className="login-wrapper">
                            <div className="loginbox">
                                <div className="login-right">
                                    <div className="login-right-wrap">
                                        <div className="account-logo">
                                            <Link to="/admin-dashboard">
                                                <img src={loginlogo} alt="#" />
                                            </Link>
                                        </div>
                                        <h2>Change Password</h2>
                                        <form onSubmit={changePasswordRequest}>
                                            <div className="form-group form-group">
                                                <label>
                                                    Current Password <span className="login-danger">*</span>
                                                </label>
                                                <input className="form-control" type={passwordVisible.current && 'password'}
                                                    onChange={handleChange} value={payload.oldPassword} name="oldPassword" />
                                                <span className="toggle-password" onClick={() => togglePass('current')} >
                                                    {passwordVisible.current ? <EyeOff size={18} className="react-feather-custom" /> : <Eye size={18} className="react-feather-custom" />}
                                                </span>
                                            </div>
                                            <div className="form-group form-group">
                                                <label>
                                                    New Password <span className="login-danger">*</span>
                                                </label>
                                                <input className="form-control" type={passwordVisible.new && 'password'} 
                                                    onChange={handleChange} value={payload.newPassword} name="newPassword" />
                                                <span className="toggle-password" onClick={() => togglePass('new')} >
                                                    {passwordVisible.new ? <EyeOff size={18} className="react-feather-custom" /> : <Eye size={18} className="react-feather-custom" />}
                                                </span>
                                            </div>
                                            <div className="form-group form-group">
                                                <label>
                                                    Confirm Password <span className="login-danger">*</span>
                                                </label>
                                                <input className="form-control" type={passwordVisible.confirm && 'password'} 
                                                    onChange={handleChange} value={payload.confirmPassword} name="confirmPassword" />
                                                <span className="toggle-password" onClick={() => togglePass('confirm')} >
                                                    {passwordVisible.confirm ? <EyeOff size={18} className="react-feather-custom" /> : <Eye size={18} className="react-feather-custom" />}
                                                </span>
                                            </div>
                                            <div className="form-group login-btn">
                                                <button className="btn btn-primary btn-block" type="submit">
                                                    Change Password
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ChangePassword;
