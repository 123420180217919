import instance from './index';
import { useMutation } from 'react-query';

const validateLogin = async (payload) => await instance.post(`/authenticate`, payload);

const changePassword = async (payload) => await instance.post(`/user/changePassword`, payload);

export function useValidateLogin() {
    return useMutation(validateLogin);
}

export function useChangePassword() {
    return useMutation(changePassword);
}

export function resetPassword({
    oldPassword = '',
    newPassword = ''
} = {}) {
    if (oldPassword === '' && newPassword === '') {
        return Promise.reject(new Error('Fields cannot be empty'));
    }
    return instance.post('/user/resetLink', {
        oldPassword: oldPassword,
        newPassword: newPassword
    })
}