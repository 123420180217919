import { useMutation, useQuery } from 'react-query';
import instance from './app-index';
import { notification } from 'antd';
import instanceImage from './app-image';

// Insurance Company APIs
const getAllInsurancePatientCompany = async (patientId) => await instance.get(`/insuranceCompanyPatient/patient/${patientId}`);

export function getPatientInsurances(patientId) {
    return instance.get(`/insuranceCompanyPatient/patient/${patientId}`);
}

const getAllPatients = async () => await instance.get('/patient');

const getPatientByBranch = async (branchId) => await instance.get(`/patient/branch/${branchId}`);

const getPatientByVisit = async (branchId) => await instance.get(`/patient/visit/${branchId}`);

const searchpatient = async (payload) => await instance.post(`/patient/search`, payload);

const addPatientInsuranceCompany = async (payload) => await instance.post(`/insuranceCompanyPatient`, payload);

const editPatientInsuranceCompany = async ({insuranceCompanyPatientId, payload}) => await instance.put(`/insuranceCompanyPatient/${insuranceCompanyPatientId}`, payload);

const getAllEthnicity = async () => await instance.get(`/ethnicity`);

const addPatient = async (payload) => await instance.post('/patient', payload);

const editPatient = async ({patientId, payload}) => await instance.put(`/patient/${patientId}`, payload);

const addVitals = async (payload) => await instance.post('/vital', payload);

const getPatientVitals = async (patientId) => await instance.get(`/vital/patient/${patientId}`);

//Patient Vitals Queries
export function useAddVitals() {
    return useMutation(addVitals);
}

export function useGetPatientVitals(patientId) {
    return useQuery(['getPatientVitals'], () => getPatientVitals(patientId));
}

// Patient Queries
export function useGetAllPatientInsuranceCompanies(patientId) {
    return useQuery(['getAllInsurancePatientCompany'], () => getAllInsurancePatientCompany(patientId));
}

export function useGetAllPatients() {
    return useQuery(['getAllPatients'], getAllPatients);
}

export function useGetPatientByBranch(branchId) {
    return useQuery(['getPatientByBranch'], () => getPatientByBranch(branchId));
}

export function useGetPatientByVisit(branchId) {
    return useQuery(['getPatientByVisit'], () => getPatientByVisit(branchId));
}

export function useSearchPatient() {
    return useMutation(searchpatient);
}

export function useAddPatientInsuranceCompany() {
    return useMutation(addPatientInsuranceCompany);
}

export function useEditPatientInsuranceCompany() {
    return useMutation(editPatientInsuranceCompany);
}

export function useGetAllEthnicity() {
    return useQuery(['getAllEthnicity'], getAllEthnicity);
}

export function useAddPatient() {
    return useMutation(addPatient);
}

export function useEditPatient() {
    return useMutation(editPatient);
}



export function getPatients() {
    return instance.get('/patient');
}

export function getPatientById(patientId) {
    return instance.get(`/patient/${patientId}`);
}

export function getEthnicity() {
    return instance.get('/ethnicity');
}

export function getInsurancePatientCompany({
    patientId = "",
}) {
    return instance.get(`/insuranceCompanyPatient/patient/${patientId}`);
}

export function insuranceCompanyPatient({
    insuranceCompanyHospitalId = '',
    patientId = '',
    status = '',
    type = '',
    planName = '',
    policyNumber = '',
    subscriberFName = '',
    subscriberMName = '',
    subscriberLName = '',
    relationship = '',
    subscriberEmployer = '',
    copay = '',
    copayType = '',
    policyType = '',
    startDate = '',
    expiryDate = '',
    meansOfVerificationType = '',
    createdBy = ''
} = {}) {
    return instance.post('/insuranceCompanyPatient', {
        insuranceCompanyHospitalId: insuranceCompanyHospitalId,
        patientId: patientId,
        status: status,
        type: type,
        planName: planName,
        policyNumber: policyNumber,
        subscriberFName: subscriberFName,
        subscriberMName: subscriberMName,
        subscriberLName: subscriberLName,
        relationship: relationship,
        subscriberEmployer: subscriberEmployer,
        copay: copay,
        copayType: copayType,
        policyType: policyType,
        startDate: startDate,
        expiryDate: expiryDate,
        meansOfVerificationType: meansOfVerificationType,
        createdBy: createdBy
    });
}

export function getVisitByPatientId(patientId) {
    return instance.get(`/patientVisit/patient/${patientId}`);
}

export function addPatientVisit({
    patientId = '',
    branchId = '',
    visitCategory = '',
    visitDate = '',
    visitTypeId = '',
    visitDescription = '',
    referredHospital = '',
    referredProvider = '',
    preferredProvider = '',
    sensibility = '',
    status = '',
    createdBy = ''

} = {}) {
    if (patientId === '' || branchId === '' || visitCategory === '' || visitDate === '' ||
        visitDescription === '' || preferredProvider === '' || sensibility === ''
    ) {
        return Promise.reject(new Error('Field cannot be empty'));
    }
    return instance.post('/visit', {
        patientId: patientId,
        branchId: branchId,
        visitCategory: visitCategory,
        visitDate: visitDate,
        visitTypeId: visitTypeId,
        visitDescription: visitDescription,
        referredHospital: referredHospital,
        referredProvider: referredProvider,
        preferredProvider: preferredProvider,
        sensibility: sensibility,
        status: status,
        createdBy: createdBy
    });
}

//Patient Visit API
const getPatientVitById = async (patientId) => await instance.get(`/patientVisit/patient/${patientId}`)

// Patient Queries
export function useGetPatientVisitId(patientId) {
    return useQuery(['getPatientVisit'], () => getPatientVitById(patientId));
}

const getAllPatientByBranch = async (branchId) => await instance.get(`/patient/branch/${branchId}`);

export function useGetAllPatientByBranch(branchId) {
    return useQuery(['getAllPatientByBranch'], () => getAllPatientByBranch(branchId));
}

const getPatientByBranchId = async (branchId) => await instance.get(`/patient/visit/${branchId}`);

export function useGetPatientByBranchId(branchId) {
    return useQuery(['getPatientByBranchId'], () => getPatientByBranchId(branchId));
}

// Patient Visit API
const getPatientDocument = async (patientId) => await instance.get(`/media/patient/${patientId}`);
const uploadPatientDocument = async (payload) => await instance.post('/media', payload);
const uploadPatientFileName = async ({filename, payload}) => await instanceImage.post(`/media/upload/${filename}`, payload);

export function useGetPatientDocument(patientId) {
    return useQuery(['getPatientDocument'], () => getPatientDocument(patientId));
}

export function useUploadPatientDocument() {
    return useMutation(uploadPatientDocument, {
        onSuccess: () => {
            notification.success({ message: 'Document uploaded successfully' });
        },
        onError: () => {
            notification.error({ message: 'Document upload failed' });
        }
    });
}

export function useUploadPatientFileName() {
    return useMutation(uploadPatientFileName, {
        onSuccess: () => {
            notification.success({ message: 'Document uploaded successfully' });
        },
        onError: () => {
            notification.error({ message: 'Document upload failed' });
        }
    });
}

